import { Component, Prop, Vue } from 'vue-property-decorator'
import FormCCSS from '@/components/CCSSForm/CCSS/CCSS.vue'
import CCSS from '@/Models/CCSS'
import http from '@/services/http'
import Search from '@/components/Search/Search.vue'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import * as Helpers from '@/helpers'
import Header from '@/components/Header/Header.vue'
import menuModule from '@/store/menu'
import router from '@/router'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormCCSS,
    Search,
    ModalDelete,
    Header,
    Animation,
  },
})
export default class Dependent extends Vue {
  showList = true
  data: Array<CCSS> = []
  objCCSS: CCSS = new CCSS()
  member: CCSS = new CCSS()
  loading = false
  delete_modal_active = false
  searchVisibility = 'is-hidden'
  btn_search = ''
  currentPage = 1
  //accion que envia dependiendo la opcion
  isAction = 'addDependent'
  //th de la tabla
  header: Array<any> = [
    {
      field: 'name_client',
      label: 'Nombre cliente',
    },
    {
      field: 'client_member_number',
      label: '# miembro',
    },
    {
      field: 'insurance_number',
      label: '# seguro',
    },
    {
      field: 'status_read',
      label: 'Estado',
    },
    {
      field: 'reported_salary',
      label: 'Salario reportado',
    },
    {
      field: 'monthly_quota',
      label: 'Cuota mensual',
    },
    {
      field: 'account_balance',
      label: 'Saldo cuenta',
    },
    {
      field: 'automatic_charge_applies_read',
      label: 'Cargo Auto',
    },
    {
      field: 'associateMemberRead',
      label: 'Papa',
    },
  ]
  mounted() {
    this.getDependent()
    ;(this.$refs.header as any).isViewPrincipal = false
  }
  async getDependent() {
    this.loading = true
    try {
      const id = menuModule.id_param
      if (id == null) throw router.push({ name: 'CCSS' }).catch(console.log)
      const res = await http.get(`/api/ccss/arcr_ccss/${id}/`)
      if (!res.data) throw new Error(res.data)
      console.log(res.data)
      if (
        res.data.associate_members !=
        'Este miembro no tiene familiares asociados CCSS'
      ) {
        this.data = res.data.associate_members.map((obj: any) => ({
          ...obj,
          id: obj.id,
          name_client: obj.member_number.client_name,
          client_member_number: obj.member_number.member_number,
          start_date: Helpers.dateParse(obj.start_date),
          status_read: obj.status.status,
          automatic_charge_applies_read:
            obj.automatic_charge_applies == 1 ? 'Si' : 'No',
          automatic_charge_applies:
            obj.automatic_charge_applies == 1 ? true : false,
          dependent: obj.dependent == 1 ? true : false,
          tariff_type: obj.type_tariff.id,
          status: obj.status.id,
          associateMemberRead: obj.associate_member.member_number,
          next_payment_date:
            obj.account_balance && obj.monthly_quota >= 0
              ? Helpers.genNextPaymentDate(
                  obj.account_balance,
                  obj.monthly_quota,
                )
              : new Date(),
        }))
      }
      this.member = res.data.ccss_owner.member_number
    } catch (error: any) {
      //console.log(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }

  editObj(props: any) {
    this.objCCSS = props
    this.showList = false
    this.isAction = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(`/api/ccss/arcr_ccss/${this.objCCSS.id}/`)
      this.data = this.data.filter((a: CCSS) => a.id !== this.objCCSS.id)
      this.delete_modal_active = false
      this.getDependent()
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }

  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objCCSS = props
  }
  closeModal() {
    this.objCCSS = new CCSS()
    this.delete_modal_active = false
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    //(this.$refs['headerForm'] as any)['showList'] = true
    this.objCCSS = new CCSS()
    this.showList = true
    this.isAction = 'add'
    await this.getDependent()
  }
  isData(data: any) {
    this.data = data
  }
}
